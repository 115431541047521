import './App.css';
import { Box, Container } from '@mui/system';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';
import DownloadIcon from '@mui/icons-material/Download';
import SWImage from './sw.png';
import FavoriteIcon from '@mui/icons-material/Favorite';

function App() {
  return (
    <div className="App">
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
          sx={{ fontSize: '12px' }}
        >
            <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                <ButtonGroup variant="text" aria-label="text button group" style={{padding:"15px", color:"white"}} >
                  <Button href="#about" style={{color:"#405366",fontSize:"18px", fontFamily:"Trirong", borderColor: "#405366"}}><b>About</b></Button>
                  <Button href="#products" style={{color:"#405366",fontSize:"18px", fontFamily:"Trirong", borderColor: "#405366"}}><b>Products</b></Button>
                  <Button href="#contactUs" style={{color:"#405366",fontSize:"18px", fontFamily:"Trirong"}}><b>Contact Us</b></Button>
                </ButtonGroup>
            </Grid>
            <Grid container columnSpacing={1} sx={{ order: { xs: 1, sm: 2 } }}>
                <Button onClick={() => {window.location.href="https://www.buymeacoffee.com/royalleaps"}}
                style={{color:"#405366",fontSize:"18px", fontFamily:"Trirong", padding:"20px"}}>
                    <FavoriteIcon sx={{ mr: 1, color:"red" }} />
                    <b>Support</b>
                </Button>
            </Grid>
        </Grid>

        <a href="about"></a>
        <p style={{
            color:"#405366",
            verticalAlign:"center",
            position:"relative",
            top:"5%",
            fontSize:"47px"
        }}>
            Our Story
        </p>

        <Container maxWidth="xl" id="about">
            <Box
                sx={{
                    borderRadius: 1,
                    bgcolor: 'white',
                    fontSize: "18px"
                }}
            >
                <div style={{padding:"15px"}}>
                    We are a small but passionate developer team based in the United Kingdom, dedicated to crafting unique and innovative applications.🌟
                    Our journey began with a shared vision—to create apps that not only entertain but also simplify everyday life.🌐
                    From the charming streets of the UK, we channel our creativity and expertise into developing small, impactful applications that
                    resonate with users around the world.<br/><br/>

                    At the core of our philosophy is a commitment to delivering experiences that bring joy and utility.💡 We believe in the power of
                    technology to enhance and enrich lives. Whether it is a playful entertainment app or a practical tool that makes daily tasks a breeze,
                    our creations are designed with you in mind.<br/><br/>

                    As a close-knit team, we take pride in our collaborative spirit and the personal touch we bring to our projects. Each app is a
                    reflection of our dedication, innovation, and a sincere desire to make a positive impact. Join us on this exciting journey, and
                    let our small yet mighty team bring a little more joy and convenience into your digital world. <br/><br/>
                    We appreciate you being part of our adventure!🙌
                </div>
            </Box>
        </Container>

        <a href="products"></a>
        <p style={{
            color:"#405366",
            verticalAlign:"center",
            position:"relative",
            top:"5%",
            fontSize:"47px"
        }}>
            Products
        </p>

        <Container maxWidth="xl" id="products">
            <Box
                sx={{
                    borderRadius: 1,
                    bgcolor: 'white',
                    fontSize: "18px"
                }}
            >
                <div style={{padding:"15px"}}>
                    <b>Introducing Shutdown Wand - Your Ultimate Timer for Windows Power Management! ⏰✨</b>
                    <Grid container spacing={2} columns={16} style={{margin:"10px 0px"}}>
                        <Grid xs={8} sx={{overflow: "hidden"}}>
                          <img src={SWImage} style={{width: "100%", top: "15%", position:"relative"}}alt="SW Home" />
                        </Grid>
                        <Grid xs={8}>
                          <b>Shutdown Wand</b> is a user-friendly and versatile application meticulously designed for Windows users, <b>available for
                          download on the Microsoft Store.</b> This nifty tool empowers you to take control of your computer's
                          shutdown and restart functions effortlessly. Whether you are waiting for a virus scan to complete, eagerly
                          anticipating the end of a lengthy game download, or simply looking to automate your power management routine,
                          Shutdown Wand has got you covered. <br/><br/>

                          Setting timers with Shutdown Wand is a breeze – you have the freedom to configure timers for as long as you need.
                          The flexibility to customize shutdown or restart times allows you to optimize your computer usage according to your
                          schedule. What sets Shutdown Wand apart is its intuitive interface that ensures users of all levels can navigate and
                          utilize its features seamlessly.🎉 <br/><br/>

                          But that is not all! We understand that plans can change, and so can your shutdown needs. Shutdown Wand grants
                          you the power to abort timers at any moment, offering unparalleled control over your system's shutdown and restart
                          processes. Best of all, this application is absolutely free, making it an accessible and invaluable addition to your
                          Windows toolkit.🆓 <br/><br/>

                          Experience the convenience and efficiency of Shutdown Wand – the timer tool that adapts to your computing lifestyle.
                          Download it today from the Microsoft Store and take charge of your shutdown and restart preferences like never before! 🚀🖥️
                        </Grid>
                      </Grid>
                      <Button size="large" variant="contained" onClick={() => window.location.href="https://royalleaps.s3.eu-west-2.amazonaws.com/Shutdown-Wand.exe"}startIcon={<DownloadIcon />} >Download Now</Button>
                  </div>
            </Box>
        </Container>

        <a href="contactUs"></a>
        <p style={{
            color:"#405366",
            verticalAlign:"center",
            position:"relative",
            top:"5%",
            fontSize:"47px"
        }}>
            Contact Us
        </p>

        <Container maxWidth="xl" id="contactUs">
            <Box
                sx={{
                    borderRadius: 1,
                    bgcolor: 'white',
                    fontSize: "18px"
                }}
            >
            <div style={{padding:"15px"}}>
                We'd love to hear from you!🌟 <br/><br/>
                At Royal Leaps, your thoughts, feedback, and comments are highly valued. Whether you have suggestions,
                want to share your experiences with our products, or need assistance with any issues, we're here for you.🤝
                Our team is dedicated to creating a seamless and enjoyable experience for our users, and your input plays a
                crucial role in achieving that goal.<br/><br/>

                Please feel free to reach out to us at <a href="mailto:info@royalleaps.com"><b>info@royalleaps.com</b></a> for any inquiries,
                comments, or concerns you may have.
                Your feedback is essential in helping us continually improve and enhance our services. Thank you for being a part of
                the Royal Leaps community!✉️
            </div>
            </Box>
        </Container>
        <br/>
    </div>
  );
}

export default App;
